import React from "react";

function article_1() {
	return {
		date: "",
		title: "Unleash the Power of Innovation with Accountant",
		link: "https://educationpointe.com/2023/09/15/%E0%A4%95%E0%A5%8D%E0%A4%AF%E0%A4%BE-%E0%A4%86%E0%A4%AA-%E0%A4%8F%E0%A4%9C%E0%A5%87%E0%A4%82%E0%A4%9F-%E0%A4%B9%E0%A5%88-%E0%A4%A4%E0%A5%8B-%E0%A4%9C%E0%A4%B0%E0%A5%81%E0%A4%B0-%E0%A4%AA%E0%A4%A2/",
		description:
			"Transform your financial agent's experience with seamless client data management and transaction tracking!",
		keywords: [
			"The Benefits of Cloud Computing",
			"Tharindu",
			"Tharindu N",
			"Tharindu Nayanajith",
		],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">Content of article 1</div>
					<img
						src="https://picsum.photos/200/300"
						alt="random"
						className="randImage"
					/>
				</div>
			</React.Fragment>
		),
	};
}

function article_2() {
	return {
		date: "7 May 2023",
		title: "Artificial Intelligence in Healthcare",
		description:
			"AI is transforming the healthcare industry, from improving patient outcomes to streamlining operations. Discover the latest applications of this game-changing technology.",
		style: ``,
		keywords: [
			"Artificial Intelligence in Healthcare",
			"Tharindu",
			"Tharindu N",
			"Tharindu Nayanajith",
		],
		body: (
			<React.Fragment>
				<h1>Content of article 2</h1>
			</React.Fragment>
		),
	};
}

const myArticles = [article_1,];

export default myArticles;
