import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import AllProjects from "../components/projects/allProjects";
import toast, { Toaster } from "react-hot-toast";

import INFO from "../data/user";
import SEO from "../data/seo";
import threeDeviceImg from "../images/three-phone.png";
import timesheetImg from "../images/timesheet.png";
import timesheetAndroidImg from "../images/timesheet-android.png";
import pmsImg from "../images/pms.png";
import payment_dashboard from "../images/payment-dashboard.png";
import contact_manager from "../images/contact-manager.png";
import teams_invoice from "../images/teams-invoice.png";
import student_portal from "../images/student-portal.png";
import join_zerodha from "../images/join-zerodha.png";
import fwr from "../images/fwr.png";
import power_bi from "../images/power-bi.png";
import more_to_come from "../images/more-to-come.png";
import vendor_management from "../images/vendor-management.png";

import "./styles/projects.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const Projects = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [projectsLists, setProjectList] = useState([
		{
			PROJECT_NAME: "Accountant",
			STATUS: "Live",
			URL: "https://accountant.pdhanewala.com/",
			DESCRIPTION:
				"Unleash the Power of Innovation Ready to streamline your financial business? Try Accountant today! Transform your financial agent's experience with seamless client data management and transaction tracking!",
			CLASS_NAME: "act-card-container accountant",
			IMAGE: threeDeviceImg,
		},
		{
			PROJECT_NAME: "TimeSheet Web",
			STATUS: "Live",
			URL: "https://timesheet.samishti.com:4000/",
			DESCRIPTION:
				"TimeSheet is resource management tool to efficiently use resources in project and manage employee time per project/per task. TimeSheet help High level management to take decision efficiently by using  recorded employees time data. and overall help in employee growth. ",
			CLASS_NAME: "act-card-container time-sheet",
			IMAGE: timesheetImg,
		},
		{
			PROJECT_NAME: "TimeSheet Android/iOS",
			STATUS: "Live",
			URL: "https://play.google.com/store/apps/details?id=com.samishtitimesheet",
			DESCRIPTION:
				"TimeSheet is resource management tool to efficiently use resources in project and manage employee time per project/per task. TimeSheet help High level management to take decision efficiently by using  recorded employees time data. and overall help in employee growth. ",
			CLASS_NAME: "act-card-container time-sheet-android",
			IMAGE: timesheetAndroidImg,
		},
		{
			PROJECT_NAME: "Performance Management System",
			STATUS: "Ready to Live",
			URL: "",
			DESCRIPTION:
				"Performance Management system serves as the backbone of effective appraisal cycles, providing a structured framework for evaluating employee performance, setting goals, and fostering continuous improvement within an organization.",
			CLASS_NAME: "act-card-container pms",
			IMAGE: pmsImg,
		},
		{
			PROJECT_NAME: "Payment Dashboard",
			STATUS: "Ready to live",
			URL: "",
			DESCRIPTION:
				"Payment Dashboard is tool to organize and assign payments to vendor on dates by MDs of companies with integration with SAP and city Bank",
			CLASS_NAME: "act-card-container payment-dashboard",
			IMAGE: payment_dashboard,
		},
		{
			PROJECT_NAME: "Contact Management System",
			STATUS: "Live",
			URL: "",
			DESCRIPTION:
				"Contact Management System is a tool used by various orgs, in CMS companies can handle cleint contact data and use that data in product promotions/brand awareness campaign by sending emails, whatsApp integrated with 3rd party and integrated with Microsoft teams. So all employees in orgs can application through microsoft teams",
			CLASS_NAME: "act-card-container cms",
			IMAGE: contact_manager,
		},
		{
			PROJECT_NAME: "Teams Invoice",
			STATUS: "Ready to live",
			URL: "https://dmsapproval.kraheja.com/",
			DESCRIPTION:
				"In teams invoice application invoice data based on logged user in Microsoft Teams appear in the dashboard. User can see details, take action like approve reject",
			CLASS_NAME: "act-card-container teams-invoice",

			IMAGE: teams_invoice,
		},
		{
			PROJECT_NAME: "Master Data Management",
			STATUS: "Ready to live",
			URL: "",
			DESCRIPTION:
				"MDM tool is use to make the onboard process of customers and vendors in a org seamless and effortless. Managing Customer and vendors with MDM is easy. This application is integrated with SAP and mondodDB as Database.",
			CLASS_NAME: "act-card-container mdm",

			IMAGE: vendor_management,
		},

		{
			PROJECT_NAME: "Student Portal",
			STATUS: "Live",
			URL: "https://pdhanewala.com",
			DESCRIPTION:
				"Student Portal is a tool for Educational Institute/Ed Freelancers who want to manage student and teachers. Batches, courses, attendance of students, salary of teachers, certificates, payments made by student to buy a course,students can chat with teachers to clear doubts and much more. Best part is, It's absolutely free to use.",
			CLASS_NAME: "act-card-container student-portal",

			IMAGE: student_portal,
		},
		{
			PROJECT_NAME: "Join Zerodha",
			STATUS: "Under Development",
			URL: "",
			DESCRIPTION:
				"Join Zerodha is paper trading platform for user who are new in stocks market and don't want to lose there capital in starting.",
			CLASS_NAME: "act-card-container join-zerodha",

			IMAGE: join_zerodha,
		},
		{
			PROJECT_NAME: "Fly Wheel Restaurant App",
			STATUS: "Under Development",
			URL: "",
			DESCRIPTION:
				"Fly wheel Restaurant App is a food dine in app means customers in Restaurant can order his food without any waiter or going on counter to order his food",
			CLASS_NAME: "act-card-container fwr",
			IMAGE: fwr,
		},
		{
			PROJECT_NAME: "School ERP System",
			STATUS: "Under Development",
			URL: "",
			DESCRIPTION:
				"School ERP System is a tool to manage school student, and staff members. Events details, STATUS chat, student bus details, student fee payments. parent login, teacher login.",
			CLASS_NAME: "act-card-container school-erp",

			IMAGE: vendor_management,
		},

		{
			PROJECT_NAME: "Buy Virtual Machine server",
			STATUS: "Live",
			URL: "https://buyserver.pdhanewala.com/",
			DESCRIPTION:
				"Welcome to our Virtual Machine Server landing page! Explore services tailored to your needs. Navigate easily, share requirements via our form.  Join us in redefining virtualization!",
			CLASS_NAME: "act-card-container buy-server",
			IMAGE: "https://www.vpsserver.com/static_content/vpsserver_com_frontend/img/vm_host_.svg",
		},
		{
			PROJECT_NAME: "Power BI Dashboard",
			STATUS: "Live",
			URL: "",
			DESCRIPTION:
				"We have created power bi dashboards to helps companies understand their activity, transform information into insights, and make data-driven decisions",
			CLASS_NAME: "act-card-container power-bi",
			IMAGE: power_bi,
		},
		{
			PROJECT_NAME: "More project to come...",
			STATUS: "Live soon...",
			URL: "",
			DESCRIPTION:
				"We are having more than 10 projects in development phase and in concept phase!",
			CLASS_NAME: "act-card-container more-project",
			IMAGE: more_to_come,
		},
	]);

	const currentSEO = SEO.find((item) => item.page === "projects");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Projects | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
					<Toaster />

					<div className="projects-container">
						<div
							className="title projects-title"
							style={{
								paddingBottom: 10,
							}}
						>
							Products We've crafted in pursuit of making a mark
							on the world
						</div>

						{projectsLists.map((val) => {
							return (
								<div
									className={val.CLASS_NAME}
									onClick={() => {
										if (val.URL != "") {
											window.open(val.URL, "_blank");
										} else {
											toast(
												"This project is not live or we cant provide live url because of security reasons! In case you need more information about this project, Please email us on given email Ids.",
												{
													icon: "👏",
												}
											);
										}
									}}
								>
									<div className="act-card-content">
										<p
											style={{
												marginLeft: 15,
											}}
											className="sub-desc"
										>
											{val.STATUS}
										</p>
										<div
											style={{
												padding: 10,
											}}
										>
											<p className="heading">
												{val.PROJECT_NAME}
											</p>
											<p className="sub-desc">
												{val.DESCRIPTION}
											</p>
										</div>
										<div
											style={{
												display: "flex",
												justifyContent: "center",
											}}
										>
											<img
												src={val.IMAGE}
												style={{
													width: 250,
													height: 200,
												}}
											/>
										</div>
									</div>
									<p></p>
								</div>
							);
						})}

						{/* <div className="projects-list">
							<AllProjects />
						</div> */}
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Projects;
